import * as PropTypes from "prop-types";
import React from "react";

const PageHeading = props => <div
    className={`has-text-weight-bold ${props.className}`}
    style={{
        backgroundColor: "rgba(214, 64, 0, 0.9)",
        color: "white",
        padding: "1rem",
        textAlign: "center"
    }}
>
    {props.heading}
</div>;

PageHeading.propTypes = {
    heading: PropTypes.string,
    className: PropTypes.string
};

export default PageHeading;

import React from 'react'
import {Link} from 'gatsby'

import PreviewCompatibleImage from './PreviewCompatibleImage'

const ArticleCard = props => {
    const post = props.post;

    return (
        <div className="column is-4">
            <article className={`blog-list-item tile ${post.frontmatter.featuredpost ? 'is-featured' : ''}`}>
                <div className="card">
                    <div className="card-image">
                        <Link className="has-text-black" to={post.fields.slug}>
                            <PreviewCompatibleImage
                                imageInfo={{
                                    image: post.frontmatter.featuredimage,
                                    alt: `featured image thumbnail for post ${post.frontmatter.title}`
                                }}
                            />
                        </Link>
                    </div>
                    <div className="card-content">
                        <div className="content">
                            <div className="mb-2">
                                <Link className="title is-4 has-text-black"
                                      to={post.fields.slug}>
                                    {post.frontmatter.title}
                                </Link>
                                <div className="m-t-tiny">{post.frontmatter.date}</div>
                            </div>
                            <p className="venue-data-sm">{post.excerpt}</p>
                            <Link className="button" to={post.fields.slug}>
                                Читать дальше →
                            </Link>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    )
};

export default ArticleCard;
